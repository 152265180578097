<div *ngIf="isShowLoading" class="loading">
    <div class="wrapper">
        <div class="container">
            <img src="../../../../../assets/images/npaperLogo.svg" alt="Logo Npaper" />
            <div class="progressbar mt-2">
            </div>
            <ng-container
                *ngIf="this.loadingSignatureService.signatureType.getValue() ==='ELETRONIC';then eletronicSignatureMessage; else digitalSignatureMessage">
            </ng-container>

            <ng-template #eletronicSignatureMessage>
                <h2>
                    Assinando documento {{this.loadingSignatureService.signatureQtdDone | async}} de
                    {{this.loadingSignatureService.signatureQtdTotal | async}}!
                </h2>
            </ng-template>

            <ng-template #digitalSignatureMessage>
                <h2>
                    Realizando assinatura {{this.loadingSignatureService.signatureQtdDone | async}} de
                    {{this.loadingSignatureService.signatureQtdTotal | async}}!
                </h2>
            </ng-template>
        </div>
    </div>
</div>