import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NLoadingSignatureModel } from './loading-signature.model';

interface ISubjectLoading {
  isStart: boolean;
  signatureType: NLoadingSignatureModel.TTipoAssinatura | null;
  qtdSignatures: number;
}

@Injectable({
  providedIn: 'root'
})
export class LoadingSignatureService {
  public isShowing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public signatureType: BehaviorSubject<NLoadingSignatureModel.TTipoAssinatura> = new BehaviorSubject<NLoadingSignatureModel.TTipoAssinatura>('ELETRONIC');
  public signatureQtdTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public signatureQtdDone: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private subject = new Subject<ISubjectLoading>();

  constructor() { }

  public getObservable(): Observable<ISubjectLoading> {
    return this.subject.asObservable();
  }

  public showLoader(pTipoAssinature: NLoadingSignatureModel.TTipoAssinatura, pQtdSignatures: number): void {
    this.subject.next({ isStart: true, signatureType: pTipoAssinature, qtdSignatures: pQtdSignatures });
    this.signatureQtdTotal.next(pQtdSignatures);
    this.signatureQtdDone.next(0);
    this.signatureType.next(pTipoAssinature);
    this.isShowing.next(true);
  }

  public hideLoader(): void {
    this.subject.next({ isStart: false, signatureType: null, qtdSignatures: 0 });

    this.signatureQtdTotal.next(0);
    this.signatureQtdDone.next(0);
    this.isShowing.next(false);
  }

  public incrementQtdSignature(): void {
    this.signatureQtdDone.next(this.signatureQtdDone.getValue() + 1);
  }
}
