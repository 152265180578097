import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingSignatureService } from './loading-signature.service';

@Component({
  selector: 'app-loading-signature',
  templateUrl: './loading-signature.component.html',
  styleUrls: ['./loading-signature.component.scss']
})
export class LoadingSignatureComponent implements OnInit, OnDestroy {

  private loadingSubscription!: Subscription;
  public isShowLoading: boolean = false;

  constructor(
    public loadingSignatureService: LoadingSignatureService
  ) { }

  ngOnInit(): void {
    this.loadingSubscription = this.loadingSignatureService.getObservable()
      .subscribe((pEvent) => {
        this.isShowLoading = pEvent.isStart;

      });
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }

}
