import { Injectable } from '@angular/core';
import { ICustomerDataResponse } from '../../models/customer';
import { KeyLocalStorage } from '../../models/key-local-storage';
import { SecretUtil } from '../../utils/secret-util';
import { IUserData } from './../../models/user.model';
import { LocalStorageService } from './../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private localStorageService: LocalStorageService
  ) { }

  public setCustomerDefault(rememberAccount: ICustomerDataResponse): boolean {
    return this.localStorageService.set(
      KeyLocalStorage.NPAPER_CUSTOMER_DEFAULT,
      SecretUtil.encrypt(JSON.stringify(rememberAccount)),
    );
  }

  public getCustomerDefault(): ICustomerDataResponse | null {
    const defaultCustomer = this.localStorageService.get(KeyLocalStorage.NPAPER_CUSTOMER_DEFAULT);
    if (defaultCustomer === null) {
      return null;
    }
    return JSON.parse(SecretUtil.decrypt(defaultCustomer));
  }

  public setUserLocal(user: IUserData): boolean {
    return this.localStorageService.set(
      KeyLocalStorage.NPAPER_USER_DATA,
      SecretUtil.encrypt(JSON.stringify(user)),
    );
  }

  public getUserLocal(): IUserData | null {
    const userData = this.localStorageService.get(KeyLocalStorage.NPAPER_USER_DATA);
    if (userData === null) {
      return null;
    }
    return JSON.parse(SecretUtil.decrypt(userData));
  }

  public deleteUserLocal(): boolean {
    return this.localStorageService.remove(KeyLocalStorage.NPAPER_USER_DATA);
  }

  public setToken(token: string): boolean {
    return this.localStorageService.set(KeyLocalStorage.NPAPER_AUTH_TOKEN, token);
  }

  public getToken(): string | null {
    return this.localStorageService.get(KeyLocalStorage.NPAPER_AUTH_TOKEN);
  }

  public deleteToken(): boolean {
    return this.localStorageService.remove(KeyLocalStorage.NPAPER_AUTH_TOKEN);
  }
}
