import { Injectable } from '@angular/core';
import { NDocumentosHttpService } from '../../models/document';
import { UrlApiNpaper } from '../../models/url-api-npaper';
import { RequestService } from '../request/request.service';

@Injectable({
    providedIn: 'root',
})
export class DocumentHttpService {
    constructor(private requestService: RequestService) { }

    public async getDocumentoAssinar(pInvolvedGuid: string, pPaperId: number): Promise<NDocumentosHttpService.IGetDocumentoAssinarResponse> {
        return await this.requestService
            .setHeaderToken()
            .get(`${UrlApiNpaper.DOCUMENT}/guid/${pInvolvedGuid}/${pPaperId}`);
    }

    public async getDocumentoVisualizar(pPaperId: number): Promise<NDocumentosHttpService.IGetDocumentoAssinarResponse> {
        return await this.requestService
            .setHeaderToken()
            .get(`${UrlApiNpaper.DOCUMENT}/paper/${pPaperId}`);
    }

    public async getDocumentoBase64Visualizar(pInvolvedGuid: string): Promise<any> {
        return await this.requestService
            .setHeaderToken()
            .get(`${UrlApiNpaper.FILE}/document/${pInvolvedGuid}`);
    }

    public async checarSecurityCode(pInvolvedGuid: string, pSecurityCode: string): Promise<NDocumentosHttpService.IDocumentChecarSecurityCodeResponse> {
        return await this.requestService
            .setHeaderToken()
            .get(`${UrlApiNpaper.DOCUMENT}/securityCode/${pInvolvedGuid}/${pSecurityCode}`);
    }

    public async getDocumentosParcialmenteAssinadoAutenticated(pPaperId: number): Promise<NDocumentosHttpService.IGetDocumentParcialmenteAssinadosResponse> {
        return await this.requestService
            .setHeaderToken()
            .post(`${UrlApiNpaper.DOCUMENT}/eletronic/download-signed/auth`, { paperId: pPaperId });
    }

    public async getDocumentosParcialmenteAssinadoNotAutenticated(pPaperId: number, pInvolvedGuid: string): Promise<NDocumentosHttpService.IGetDocumentParcialmenteAssinadosResponse> {
        return await this.requestService
            .setHeaderToken()
            .post(`${UrlApiNpaper.DOCUMENT}/eletronic/download-signed/not-auth`, { paperId: pPaperId, involvedGuid: pInvolvedGuid });
    }
}
