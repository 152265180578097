import { Injectable } from '@angular/core';
import { LoadingSignatureService } from 'src/app/shared/components/settings/loading-signature/loading-signature.service';
import { EletronicSignData } from 'src/app/shared/models/eletronicSignData';
import { SignatureEletronic } from 'src/app/shared/models/signature';
import SignatureDocument from 'src/app/shared/models/signatureDocument';
import { IpService } from '../../ip/ip.service';
import { LocationService } from '../../location/location.service';
import { SignatureHttpService } from '../signature.http.service';

@Injectable()
export class SignatureEletronicService {
    constructor(
        private ipService: IpService,
        private locationService: LocationService,
        private signatureHttpService: SignatureHttpService,
        private loadingSignatureService: LoadingSignatureService,
    ) { }

    /**
     * Funcionalidade para realizar o processo de assinatura eletronica.
     * @param pDocuments Documentos a serem assinados.
     * @param pAssinatura Dados da assinatura;
     * @returns Indicador se o envio está totalmente assinado
     */
    public async execute(pDocuments: SignatureDocument[], pAssinatura: EletronicSignData): Promise<boolean> {
        try {
            this.loadingSignatureService.showLoader('DIGITAL', pDocuments.length);

            const ip = await this.ipService.getUserIp();
            if (ip === null) {
                throw new Error('Não foi possível pegar o IP para realizar a assinatura.Tente novamente ou entre em contato com o suporte através do chat');
            }
            const location = await this.locationService.getLocation();
            const signaturesPromises = pDocuments.map(async (pDoc) => {
                const eletronic = new SignatureEletronic([pDoc], pAssinatura, location, ip);
                const response = await this.signatureHttpService.assinarEletronico(eletronic.gerarRequest());
                this.loadingSignatureService.incrementQtdSignature();
                return response;
            });

            const resultAssinaturas = await Promise.all(signaturesPromises);
            this.loadingSignatureService.hideLoader();
            return Promise.resolve(resultAssinaturas.some(pAss => pAss.isTotalmenteAssinado === true));
        } catch (error) {
            this.loadingSignatureService.hideLoader();
            console.log(error);
            return Promise.reject(error);
        }
    }
}
