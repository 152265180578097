<div class="input-checkbox" [class]="disableCheckIfDisabledAndNotChecked()">
  <input
  class="mr-2"
  type="checkbox"
  [id]="id"
  [checked]="checked === undefined ? isChecked() : checked"
  [disabled]="this.form.disabled"
  (click)="onChecked(!this.form.value)"
  />
  <label [for]="id">{{label}}<ng-content select="[label]"></ng-content></label>
</div>